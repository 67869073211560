import React, { useEffect, useState, useRef } from 'react';
import { getLocale, getLang, Tooltip, ConfigProvider, AutoComplete, Empty, message, Avatar, Spin, Button, Segmented } from '@didi/ec-base';
import { DHRCardButton } from '@didi/ec-ehr-base';
import { RightThickOutlined } from '@didi/iconflow/ec';
import { Intl, drawer } from '@didi/pagelets-sdk';
import {
  teamEmplValueMap,
  teamEmplOutsourcingLabelMap,
  teamEmplLabelMap,
  teamTitleMap,
  onBoardingTypeMap,
  onTheWayTypeMap,
  transferKeyMap,
  transferTitleMap,
  onTheWayTitleMap,
} from './constants';
import manifest from '../manifest.json';
import PageletServices from './services';
import classNames from 'classnames';

import debounce from "lodash/debounce"; //函数防抖
import { ReactComponent as More } from './assets/more.svg';
import { ReactComponent as Search } from './assets/search.svg'
import { typeList } from './constant.jsx';
import useStyle from './style';

const localLocales = manifest.locales || {};

// 团队成员抽屉
const teamDrawer = drawer({
  title: '团队成员',
  rootClassName: 'overview-members-drawer',
  // pageletUrl: '//127.0.0.1:9101/overview-members.runtime.js',
  pageletName: '@didi/pagelet-overview-members',
  pageletProps: {},
  destroyOnClose: true,
  width: '1000px',
  onClose: () => {
    teamDrawer.hide();
  },
});

// 试用期人员
const probationDrawer = drawer({
  title: '试用期人员',
  rootClassName: 'overview-probation-drawer',
  // pageletUrl: '//127.0.0.1:9300/overview-probation.runtime.js',
  pageletName: '@didi/pagelet-overview-probation',
  pageletProps: {},
  destroyOnClose: true,
  width: '1000px',
  onClose: () => {
    probationDrawer.hide();
  },
});

// 在途人数
const onthewayDrawer = drawer({
  title: '在途人数',
  rootClassName: 'overview-ontheway-drawer',
  // pageletUrl: '//127.0.0.1:9200/overview-ontheway.runtime.js',
  pageletName: '@didi/pagelet-overview-ontheway',
  pageletProps: { type: 'tomorrow', deptCodes: [] },
  destroyOnClose: true,
  width: '1000px',
  onClose: () => {
    onthewayDrawer.hide();
  },
});

// 预计离职人员
const transferDrawer = drawer({
  title: '预计转入人员',
  rootClassName: 'overview-transfer-drawer',
  // pageletUrl: '//127.0.0.1:9400/overview-transfer.runtime.js',
  pageletName: '@didi/pagelet-overview-transfer',
  pageletProps: { type: 'offBoarding', deptCodes: [] },
  destroyOnClose: true,
  width: '1000px',
  onClose: () => {
    transferDrawer.hide();
  },
});

// 团队总览
const Pagelet = props => {
  const {
    params: { apiHost, locales, auth, item, deptCodes, deptInfos, pageCode, headers },
  } = props;

  // 卡片信息
  const [overviewData, setOverviewData] = useState({});
  const [overviewLoading, setOverviewLoading] = useState(false);
  const [deptList, setDeptList] = useState({});

  const [abnormal, setAbnormal] = useState(false); //是否展示异常页面

  const [options, setOptions] = useState(); // 搜索下拉内容
  const [searchValue, setSearchValue] = useState(undefined); // 搜索的内容
  const [loading, setLoading] = useState(false); // loading

  const [typeCode, setTypeCode] = useState('1');

  const searchRef = useRef(null);
  const initSrc = '//img-hxy021.didistatic.com/static/ehr_static/imgs/canghai/cards/search.png';
  const emptySrc = '//img-hxy021.didistatic.com/static/ehr_static/imgs/DHR/no_data_image.png';

  // class
  const prefixCls = 'pagelet-team-big-overview';
  const wrapSSR = useStyle(prefixCls);
  const classes = classNames(prefixCls);

  // 多语言优先 props，再取本地
  const langLocales = locales || localLocales;
  const locale = getLocale(langLocales);
  const intl = Intl(locale);
  const langEn = getLang()?.toLowerCase().includes('us');

  // 跳转组织
  const handleToOrg = () => {
    window.open(`${location.origin}/team`, '_blank');
  };

  // 正式员工、实习生、工时制外包
  const handleTeamDrawer = emplValue => {
    // emplValue => OUTSOURCING
    try {
      let label = '';
      let type = '';
      if (emplValue === teamEmplValueMap.OUTSOURCING) {
        type = 'OUT_HRO';
        label = teamEmplOutsourcingLabelMap['OUT_HRO'];
      } else {
        type = emplValue;
        label = intl(teamEmplLabelMap[emplValue]);
      }

      teamDrawer.show({
        dynamicDrawerProps: { title: intl(teamTitleMap[emplValue]) },
        dynamicPageletProps: {
          auth,
          headers,
          deptCodes,
          deptInfos,
          pageCode,
          emplValue: type,
          emplLabel: label,
          type: emplValue,
        },
      });
    } catch (e) {
      console.log(e);
    }
  };

  // 试用期人员
  const handleProbationDrawer = (evt, viewType) => {
    probationDrawer.show({
      dynamicPageletProps: {
        auth,
        headers,
        deptCodes,
        deptInfos,
        pageCode,
        defaultViewType: viewType,
      },
    });
    evt.stopPropagation();
  };

  // 在途人员、预计入职、预计离职
  const handleOnthewayDrawer = (evt, type, boardingType) => {
    onthewayDrawer.show({
      dynamicDrawerProps: { title: intl(onTheWayTitleMap[type]) },
      dynamicPageletProps: {
        auth,
        headers,
        deptCodes,
        deptInfos,
        pageCode,
        type,
        boardingType,
      },
    });
    evt.stopPropagation();
  };

  // 转入转出
  const handleTransferDrawer = transferKey => {
    transferDrawer.show({
      dynamicDrawerProps: { title: intl(transferTitleMap[transferKey]) },
      dynamicPageletProps: {
        auth,
        headers,
        deptCodes,
        deptInfos,
        pageCode,
        transferKey,
      },
    });
  };

  // 获取卡片数据
  const getOverviewData = async (auth, deptCodes) => {
    try {
      const authId = auth.id;
      const { data } = await PageletServices.getStatistics(
        apiHost,
        { deptCodes },
        {
          ...headers,
          functionId: authId,
          'bricks-function-id': authId,
        },
      );
      setOverviewData(data);
      setDeptList(data?.deptInfoVO);
      setOverviewLoading(false);
    } catch (e) {
      setAbnormal(true);
      console.log(e);
      setOverviewLoading(false);
    }
  };

  // 接口请求示例, 部门变动后重新请求
  useEffect(() => {
    if (!auth?.id) return;
    setOverviewLoading(true);
    getOverviewData(auth, deptCodes);
  }, [deptCodes, auth]);
  // 搜索方法
  const handleSearch = async (value) => {
    const param = { deptCodes: deptCodes.join(), pageType: pageCode, keyWord: value };
    const res = await PageletServices.getSearchData(apiHost, param, {
      ...headers,
      'bricks-function-id': auth?.id,
    });
    if (res?.code === "100200") {
      setOptions(res?.data || { data: [] });
      setLoading(false);
    } else {
      message.error(res?.msg);
      setLoading(false);
    }
  };
  // 使用 useEffect 来监听 searchValue 变化
  useEffect(() => {
    // 无内容不搜索
    if (!searchValue) {
      setOptions({ data: [] });
      setLoading(false);
      return;
    };
    setLoading(true);
    const debouncedHandleSearch = debounce(() => handleSearch(searchValue), 200);
    debouncedHandleSearch();
    return () => debouncedHandleSearch.cancel(); // 清除防抖函数
  }, [searchValue]);

  // 处理部门展示
  const handleDeptpatd = value => {
    const deptArr = value.split('>');
    const str = deptArr.length > 2
      ? ''.concat(deptArr[0], '>...>').concat(deptArr[deptArr.length - 1])
      : value;
    return str;
  };

  // 高亮提示搜索的内容
  const highlightText = (text, search) => {
    if (!search) return text;
    const regex = new RegExp(`(${search})`, 'gi');
    const parts = text.split(regex);
    return parts.map((part, index) =>
      part?.toLowerCase() === search?.toLowerCase() ?
        <span key={index} style={{ color: '#FF6400' }}>{part}</span> :
        part
    );
  };

  // 下拉选项内容
  const optionsList = options?.data?.map(item => {
    const { photo, name, empId, deptPath, label } = item;
    return {
      value: `${name}-${empId}-${deptPath}`,
      label: (
        <div className='item-option'>
          <Avatar size={28} src={photo} />
          <div className='item-option-content'>
            <p className='item-option-content-name'>
              {highlightText(name, searchValue)}
              {label === 1 && <span className='quit'>离职</span>}
              {label === 2 && <span className='internship'>实习</span>}
            </p>
            <Tooltip title={deptPath} placement="bottom" overlayClassName='canghai-search-tooltip'>
              <div className='item-option-content-dept'>
                {highlightText(empId, searchValue)}
                <span> | </span>
                <span>{highlightText(handleDeptpatd(deptPath), searchValue)}</span>
              </div>
            </Tooltip>
          </div>
        </div>
      ),
      key: `${options?.naviUrl}empId=${item?.empId}`
    };
  });
  const getMoreList = (list) => {
    let info = '';
    let length = list.length;
    list.map((v, index) => {
      info += v?.name + v?.empId + (index + 1 !== length ? '、' : '')
    })
    return <span>{info}</span>;
  }
  const AutoCompleteRef = useRef(null);
  return wrapSSR(
    <ConfigProvider prefixCls="ant5">
      <div className={classes}>
        <section className="title">
          <h3>团队总览</h3>
        </section>
        <div
          className='overview-search-modal'
          ref={searchRef}
        >
          <AutoComplete
            ref={AutoCompleteRef}
            virtual={false}
            options={optionsList}
            placeholder='输入邮箱前缀/工号/姓名查询员工档案'
            onSearch={setSearchValue}
            value={searchValue}
            open={true}
            onSelect={(_, option) => {
              AutoCompleteRef.current.blur();
              window.open(option?.key, '_blank');
              setSearchValue(undefined);
              setOpenFlag(false);
            }}
            onBlur={()=>{
              setOptions([]);
              setSearchValue(undefined);
            }}
            getPopupContainer={e => e.parentNode}
            notFoundContent={(searchValue && !optionsList?.length) ? (loading ? <div className='data-loading'>
              <Spin spinning={loading} />
              <span>数据加载中</span>
            </div> : <Empty image={emptySrc} description='暂无数据' />) : null}
          />
          <Search className="overview-search-modal-search"/>
        </div>
        {abnormal && (
          <div className="overview-abnormal">
            <img
              src={'//img-hxy021.didistatic.com/static/ehr_static/imgs/DHR/data_anomaly_image.png'}
              style={{ width: `${item?.cardHeight === 1 ? '80px' : '120px'}` }}
            />
            <span>服务不可用，请稍候再试。</span>
          </div>
        )}
        {!abnormal && <section className="title-info">
          <div className="title-info-tit">成员信息</div>
        </section>}
        {!abnormal && (
          <section className={classNames('content', { 'content-english': langEn })}>
            <div className="present">
              {/* 正式员工、实习生、工时制外包 */}
              <div className="present-employee">
                <div className="space" onClick={() => handleTeamDrawer(teamEmplValueMap.CTR)}>
                  <div className="block">
                    <p className="block-number">
                      {overviewData?.employeeStatisticsVO?.onJobPermanentNum}
                    </p>
                    <Tooltip
                      placement="bottomLeft"
                      title={
                        <div className="tooltip-title">
                          <h4>{intl('FULL-TIME_EMPLOYEE')}</h4>
                          <span>
                            正式员工在岗人数，正式员工指员工性质为正式员工、劳务，不含实习生、外包人员
                          </span>
                        </div>
                      }
                    >
                      <p className="block-name">{intl('FULL-TIME_EMPLOYEE')}</p>
                    </Tooltip>
                  </div>
                </div>
                <div className="space" onClick={() => handleTeamDrawer(teamEmplValueMap.INT)}>
                  <div className="block">
                    <p className="block-number">
                      {overviewData?.employeeStatisticsVO?.onJobTraineeNum}
                    </p>
                    <Tooltip
                      placement="bottomLeft"
                      title={
                        <div className="tooltip-title">
                          <h4>{intl('INTERN')}</h4>
                          <span>实习生在岗人数</span>
                        </div>
                      }
                    >
                      <p className="block-name" title={intl('INTERN')}>
                        {intl('INTERN')}
                      </p>
                    </Tooltip>
                  </div>
                </div>
                <div
                  className="space"
                  onClick={() => handleTeamDrawer(teamEmplValueMap.OUTSOURCING)}
                >
                  <div className="block">
                    <p className="block-number outsourcing">
                      {overviewData?.outsourcingStatisticsVO?.onJobManhourNum}
                    </p>
                    <Tooltip
                      placement="bottomLeft"
                      title={
                        <div className="tooltip-title">
                          <h4>{intl('HOURLY_OUTSOURCING')}</h4>
                        </div>
                      }
                    >
                      <p className="block-name" title={intl('HOURLY_OUTSOURCING')}>
                        {intl('HOURLY_OUTSOURCING')}
                      </p>
                    </Tooltip>
                  </div>
                </div>
              </div>
              {/* 试用期人数 */}
              <div className="present-probation">
                <div className="space" onClick={evt => handleProbationDrawer(evt, 1)}>
                  <div className="block">
                    <p className="block-number">
                      {overviewData?.probationStatisticsVO?.allQuantity}
                    </p>
                    <Tooltip
                      placement="bottomLeft"
                      title={
                        <div className="tooltip-title">
                          <h4>{intl('PROBATION_PERIOD')}</h4>
                          <span>尚未转正的在职员工。</span>
                        </div>
                      }
                    >
                      <p className="block-name" title={intl('PROBATION_PERIOD')}>
                        {intl('PROBATION_PERIOD')}
                      </p>
                    </Tooltip>
                  </div>
                  {overviewData?.probationStatisticsVO?.allQuantity > 0 && (
                    <div className="block-expand">
                      <Tooltip
                        placement="left"
                        title={
                          <div className="tooltip-title">
                            <h4>{intl('PENDING')}</h4>
                            <span>展示所有待您处理的团队试用期流程</span>
                          </div>
                        }
                      >
                        <p onClick={evt => handleProbationDrawer(evt, 2)}>
                          <span title={intl('PENDING')}>{intl('PENDING')}：</span>
                          {overviewData?.probationStatisticsVO?.pendingQuantity}
                        </p>
                      </Tooltip>
                      <Tooltip
                        placement="left"
                        title={
                          <div className="tooltip-title">
                            <h4>{intl('RISK')}</h4>
                            <span>
                              展示团队内处于试用期且中期/转正评估结果为不合格/不同意转正的成员
                            </span>
                          </div>
                        }
                      >
                        <p onClick={evt => handleProbationDrawer(evt, 3)}>
                          <span title={intl('RISK')}>{intl('RISK')}：</span>
                          {overviewData?.probationStatisticsVO?.riskQuantity}
                        </p>
                      </Tooltip>
                      <Tooltip
                        placement="left"
                        title={
                          <div className="tooltip-title">
                            <h4>{intl('TIMEOUT')}</h4>
                            <span>展示所有进行中且相应审批节点已超时未审批的流程</span>
                          </div>
                        }
                      >
                        <p onClick={evt => handleProbationDrawer(evt, 4)}>
                          <span title={intl('TIMEOUT')}>{intl('TIMEOUT')}：</span>
                          {overviewData?.probationStatisticsVO?.timeOutQuantity}
                        </p>
                      </Tooltip>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="ongoing">
              {/* 在途人数、预计转入、已入职/预入职 */}
              <div className="ongoing-entry">
                <div
                  className="space ontheway"
                  onClick={evt =>
                    handleOnthewayDrawer(evt, onTheWayTypeMap.tomorrow, onBoardingTypeMap.all)
                  }
                >
                  <div className="block">
                    <p className="block-number">{overviewData?.entryStatisticsVO?.onTheWayNum}</p>
                    <Tooltip
                      placement="bottomLeft"
                      title={
                        <div className="tooltip-title">
                          <h4>{intl('IN_TRANSIT')}</h4>
                          <span>社招在途和校招在途的总人数</span>
                        </div>
                      }
                    >
                      <p className="block-name" title={intl('IN_TRANSIT')}>
                        {intl('IN_TRANSIT')}
                      </p>
                    </Tooltip>
                  </div>
                </div>
                <div
                  className="space transfer-in"
                  onClick={() => handleTransferDrawer(transferKeyMap.into)}
                >
                  <div className="block">
                    <p className="block-number">{overviewData?.transferStatisticsVO?.transferIn}</p>
                    <Tooltip
                      placement="bottomLeft"
                      title={
                        <div className="tooltip-title">
                          <h4>{intl('TRANSFER_IN')}</h4>
                          <span>
                            调动流程审批状态为【审批中】或审批状态为【已完成】，
                            预计调动日期为当前日期及以后的转入人数（从外部门转到所在部门）。
                          </span>
                        </div>
                      }
                    >
                      <p className="block-name" title={intl('TRANSFER_IN')}>
                        {intl('TRANSFER_IN')}
                      </p>
                    </Tooltip>
                  </div>
                </div>
                <div
                  className="space onboarding"
                  onClick={evt => handleOnthewayDrawer(evt, onTheWayTypeMap.today, null)}
                >
                  <div className="block">
                    <p className="block-number">
                      {overviewData?.entryStatisticsVO?.todayHiredNum}
                      <span>/{overviewData?.entryStatisticsVO?.todayNum}</span>
                    </p>
                    <Tooltip
                      placement="bottomLeft"
                      title={
                        <div className="tooltip-title">
                          <h4>{intl('ONBOARD')}</h4>
                          {/* <span>社招在途和校招在途的总人数</span> */}
                        </div>
                      }
                    >
                      <p className="block-name" title={intl('ONBOARD')}>
                        {intl('ONBOARD')}
                      </p>
                    </Tooltip>
                  </div>
                </div>
              </div>
              {/* 预计转出、预计离职 */}
              <div className="ongoing-depart">
                <div
                  className="space transfer-out"
                  onClick={() => handleTransferDrawer(transferKeyMap.out)}
                >
                  <div className="block">
                    <p className="block-number">
                      {overviewData?.transferStatisticsVO?.transferOut}
                    </p>
                    <Tooltip
                      placement="bottomLeft"
                      title={
                        <div className="tooltip-title">
                          <h4>{intl('TRANSFER_OUT')}</h4>
                          <span>
                            调动流程审批状态为【已完成】，
                            预计调动日期为当前日期及以后的转出人数（从所在部门转到外部门）。
                          </span>
                        </div>
                      }
                    >
                      <p className="block-name" title={intl('TRANSFER_OUT')}>
                        {intl('TRANSFER_OUT')}
                      </p>
                    </Tooltip>
                  </div>
                </div>
                <div
                  className="space off-boarding"
                  onClick={evt => handleOnthewayDrawer(evt, onTheWayTypeMap.offBoarding, null)}
                >
                  <div className="block">
                    <p className="block-number">
                      {overviewData?.leaveStatisticsVO?.expectedLeaveNum}
                    </p>
                    <Tooltip
                      placement="bottomLeft"
                      title={
                        <div className="tooltip-title">
                          <h4>{intl('ATTRITION')}</h4>
                          <span>员工状态为预计离职的正式员工数量。</span>
                        </div>
                      }
                    >
                      <p className="block-name" title={intl('ATTRITION')}>
                        {intl('ATTRITION')}
                      </p>
                    </Tooltip>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
        {<section className="title-info">
          <Segmented
            value={typeCode}
            options={typeList}
            onChange={(value) => {
              setTypeCode(value)
            }}
          />
          <DHRCardButton onClick={() => {
             let url = '';
             if(window.location?.pathname === '/management'){
               url = `${location.origin}/org/team`
             } else {
                url = `${location.origin}/organization`
             }
             window.open(url, '_blank');
          }}>
            查看详情 <RightThickOutlined cursor="pointer" size={14} className="team-big-overview-url-icon" />
          </DHRCardButton>
        </section>}
        {overviewLoading ? <Spin spinning={overviewLoading} /> :
          typeCode === '1'
          ? Object.keys(deptList).length > 0 && <div className="dept-info-warp">
            <div className="dept-info">
              <div className="dept-info-tit">
                HRBP：
              </div>
              <div className="dept-info-content">
                {deptList?.hrBpList[0]?.name ? <Tooltip arrow={false} title={<span>{deptList?.hrBpList[0]?.name} {deptList?.hrBpList[0]?.empId}</span>}>
                  <div
                    className="dept-info-content-info"
                    onClick={() => {window.open(`dchat://im/start_conversation?name=${deptList?.hrBpList[0]?.ldap}`)}}
                  >
                    {deptList?.hrBpList[0]?.name} {deptList?.hrBpList[0]?.empId}
                  </div>
                </Tooltip> : <div className="dept-info-content-infos">-</div>}
                {deptList?.hrBpList.length > 1 && <Tooltip
                  title={
                    getMoreList(deptList?.hrBpList)
                  }
                  placement="top"
                  trigger="hover"
                  arrow={false}
                  overlayClassName='canghai-search-tooltip'
                >
                  <div className="dept-info-content-more">
                    更多<RightThickOutlined />
                  </div>
                </Tooltip>}
              </div>
            </div>
            <div className="dept-info">
              <div className="dept-info-tit">
                HRBP 经理：
              </div>
              <div className="dept-info-content">
                {deptList?.hrBpManagerList[0]?.name ? <Tooltip arrow={false} title={<span>{deptList?.hrBpManagerList[0]?.name} {deptList?.hrBpManagerList[0]?.empId}</span>}>
                  <div
                    className="dept-info-content-info"
                    onClick={() => {window.open(`dchat://im/start_conversation?name=${deptList?.hrBpManagerList[0]?.ldap}`)}}
                  >
                    {deptList?.hrBpManagerList[0]?.name} {deptList?.hrBpManagerList[0]?.empId}
                  </div>
                </Tooltip> : <div className="dept-info-content-infos">-</div>}
                {deptList?.hrBpManagerList.length > 1 && <Tooltip
                  title={
                    getMoreList(deptList?.hrBpManagerList)
                  }
                  placement="top"
                  trigger="hover"
                  arrow={false}
                  overlayClassName='canghai-search-tooltip'
                >
                  <div className="dept-info-content-more">
                    更多<RightThickOutlined />
                  </div>
                </Tooltip>}
              </div>
            </div>
            <div className="dept-info">
              <div className="dept-info-tit">
                HRBP HEAD：
              </div>
              <div className="dept-info-content">
               {deptList?.hrBpHeadList[0]?.name ? <Tooltip arrow={false} title={<span>{deptList?.hrBpHeadList[0]?.name} {deptList?.hrBpHeadList[0]?.empId}</span>}>
                  <div
                    className="dept-info-content-info"
                    onClick={() => {window.open(`dchat://im/start_conversation?name=${deptList?.hrBpHeadList[0]?.ldap}`)}}
                  >
                    {deptList?.hrBpHeadList[0]?.name} {deptList?.hrBpHeadList[0]?.empId}
                  </div>
                </Tooltip> : <div className="dept-info-content-infos">-</div>}
                {deptList?.hrBpHeadList.length > 1 && <Tooltip
                  title={
                    getMoreList(deptList?.hrBpHeadList)
                  }
                  placement="top"
                  trigger="hover"
                  arrow={false}
                  overlayClassName='canghai-search-tooltip'
                >
                  <div className="dept-info-content-more">
                    更多<RightThickOutlined />
                  </div>
                </Tooltip>}
              </div>
            </div>
            <div className="dept-info">
              <div className="dept-info-tit">
                HRBP PMO ：
              </div>
              <div className="dept-info-content">
                {deptList?.hrbpPmoList[0]?.name ? <Tooltip arrow={false} title={<span>{deptList?.hrbpPmoList[0]?.name} {deptList?.hrbpPmoList[0]?.empId}</span>}>
                  <div
                    className="dept-info-content-info"
                    onClick={() => {window.open(`dchat://im/start_conversation?name=${deptList?.hrbpPmoList[0]?.ldap}`)}}
                  >
                    {deptList?.hrbpPmoList[0]?.name} {deptList?.hrbpPmoList[0]?.empId}
                  </div>
                </Tooltip> : <div className="dept-info-content-infos">-</div>}
                {deptList?.hrbpPmoList.length > 1 && <Tooltip
                  title={
                    getMoreList(deptList?.hrbpPmoList)
                  }
                  placement="top"
                  trigger="hover"
                  arrow={false}
                  overlayClassName='canghai-search-tooltip'
                >
                  <div className="dept-info-content-more">
                    更多<RightThickOutlined />
                  </div>
                </Tooltip>}
              </div>
            </div>
          </div>
          : Object.keys(deptList).length > 0 && <div className="dept-info-warp">
            <div className="dept-info">
              <div className="dept-info-tit">
                财务BP ：
              </div>
              <div className="dept-info-content" >
                {deptList?.financialBpList[0]?.name ? <Tooltip arrow={false} title={<span>{deptList?.financialBpList[0]?.name} {deptList?.financialBpList[0]?.empId}</span>}>
                  <div
                    className="dept-info-content-info"
                    onClick={() => {window.open(`dchat://im/start_conversation?name=${deptList?.financialBpList[0]?.ldap}`)}}
                  >
                    {deptList?.financialBpList[0]?.name} {deptList?.financialBpList[0]?.empId}
                  </div>
                </Tooltip> : <div className="dept-info-content-infos">-</div>}
                {deptList?.financialBpList.length > 1 && <Tooltip
                  title={
                    getMoreList(deptList?.financialBpList)
                  }
                  placement="top"
                  trigger="hover"
                  arrow={false}
                  overlayClassName='canghai-search-tooltip'
                >
                  <div className="dept-info-content-more">
                    更多<RightThickOutlined />
                  </div>
                </Tooltip>}
              </div>
            </div>
            <div className="dept-info" onMouseLeave={()=>{setTaxationBpOpen(false);}}>
              <div className="dept-info-tit">
                税务BP ：
              </div>
              <div className="dept-info-content">
                {deptList?.taxationBpList[0]?.name ? <Tooltip arrow={false} title={<span>{deptList?.taxationBpList[0]?.name} {deptList?.taxationBpList[0]?.empId}</span>}>
                  <div
                    className="dept-info-content-info"
                    onClick={() => {window.open(`dchat://im/start_conversation?name=${deptList?.taxationBpList[0]?.ldap}`)}}
                  >
                    {deptList?.taxationBpList[0]?.name} {deptList?.taxationBpList[0]?.empId}
                  </div>
                </Tooltip> : <div className="dept-info-content-infos">-</div>}
                {deptList?.taxationBpList.length > 1 && <Tooltip
                  title={
                    getMoreList(deptList?.taxationBpList)
                  }
                  placement="top"
                  trigger="hover"
                  arrow={false}
                  overlayClassName='canghai-search-tooltip'
                >
                  <div className="dept-info-content-more">
                    更多<RightThickOutlined />
                  </div>
                </Tooltip>}
              </div>
            </div>
            <div className="dept-info">
              <div className="dept-info-tit">
                法务BP ：
              </div>
              <div className="dept-info-content">
                {deptList?.legalBpList[0]?.name ? <Tooltip arrow={false} title={<span>{deptList?.legalBpList[0]?.name} {deptList?.legalBpList[0]?.empId}</span>}>
                  <div
                    className="dept-info-content-info"
                    onClick={() => {window.open(`dchat://im/start_conversation?name=${deptList?.legalBpList[0]?.ldap}`)}}
                  >
                    {deptList?.legalBpList[0]?.name} {deptList?.legalBpList[0]?.empId}
                  </div>
                </Tooltip> : <div className="dept-info-content-infos">-</div>}
                {deptList?.legalBpList.length > 1 && <Tooltip
                  title={getMoreList(deptList?.legalBpList)}
                  placement="top"
                  trigger="hover"
                  arrow={false}
                  overlayClassName='canghai-search-tooltip'
                >
                  <div className="dept-info-content-more">
                    更多<RightThickOutlined />
                  </div>
                </Tooltip>}
              </div>
            </div>
          </div>
          }
      </div>
    </ConfigProvider>,
  );
};

export default Pagelet;
